import React, { useState }          from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import useAlgoliaClient  from './hooks/useAlgoliaClient'

import SearchBox                from './algolia/SearchBox'
import ToggleRefinementCheckbox from './algolia/ToggleRefinementCheckbox'
import MenuSelect               from './algolia/MenuSelect'
import Hits                     from './algolia/Hits'
import Pagination               from './algolia/Pagination'

import Hit          from './ProductsIndex/Hit'
import ToggleActive from './ProductsIndex/ToggleActive'

const ProductsIndex = ({appId, apiKey, indexName}) => {
  const searchClient      = useAlgoliaClient(appId, apiKey)
  const [state, setState] = useState(null)

  const typeFilter = state && state.menu ? state.menu.type : null

  return <InstantSearch indexName={indexName} searchClient={searchClient} onSearchStateChange={setState}>
    <Configure hitsPerPage={50} />

    <div className='row'>
      <div className='col-md-8'>
        <SearchBox />
       </div>
      <div className='col-md-4 text-right'>
        <a href='/de/admin/products/new' className='btn btn-primary'>New Product</a>
      </div>
    </div>
    <div className='row mt-md-3'>
      <div className='col-md-8'>
        <div className='d-inline-block mr-4'><ToggleRefinementCheckbox attribute='has_active_offers' label='active offers' value={true} /></div>
        <div className='d-inline-block mr-4'><ToggleRefinementCheckbox attribute='no_series' label='no series' value={true} /></div>
        <div className='d-inline-block mr-4'><ToggleRefinementCheckbox attribute='no_sku' label='no sku' value={true} /></div>
        <div className='d-inline-block mr-4'><ToggleActive label='active' /></div>
      </div>
      <div className='col-md-4 text-right'>
        <div className='d-inline-block'><MenuSelect attribute='latest_update_by' label='latest update' /></div>
        <div className='d-inline-block'><MenuSelect attribute='type' label='type' /></div>
      </div>
    </div>
    <table className='table table-hover table-top-borderless mt-md-3'>
      <thead>
        <tr>
          <th>ID</th>
          <th className='name'>Product name</th>
          <th className='name'>Series name</th>
          <th className='details'>Product info</th>
          {typeFilter === 'battery' && <th>UN3480</th>}
          <th className='product-no'>KRA</th>
          <th className='product-no'>IBC</th>
          <th className='product-no'>SEG</th>
          <th className='product-no'>BAY</th>
          <th className='product-no'>GRA</th>
          <th className='product-no'>LIB</th>
          <th className='product-no'>GCL</th>
          <th className='check'>Upl</th>
          <th className='check'>Des</th>
          <th className='check'>Img</th>
        </tr>
      </thead>
      <tbody>
        <Hits Hit={Hit} colspan={10} typeFilter={typeFilter} />
      </tbody>
    </table>
    <Pagination />
  </InstantSearch>
}

export default ProductsIndex
