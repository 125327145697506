import { Controller } from "stimulus"

const DEFAULT_CSS_CLASS = "is--hidden"

export default class extends Controller {
  static targets = ["toggle", "switch"]

  static values = {
    locale: String,
    checkboxSelector: String
  }

  connect() {
    this.switchTarget.addEventListener("change", this.toggle.bind(this))
  }

  hide(event) {
    event.stopPropagation()
    event.preventDefault()

    this.localCheckboxes.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.toggleTarget.classList.add(DEFAULT_CSS_CLASS)
  }

  show(event) {
    event.stopPropagation()
    event.preventDefault()

    this.allCheckboxes.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.localCheckboxes.forEach((checkbox) => {
      checkbox.checked = true
    })
    this.toggleTarget.classList.remove(DEFAULT_CSS_CLASS)
  }

  toggle(event) {
    event.stopPropagation()
    event.preventDefault()

    if(event.target.checked) {
      this.show(event)
    } else {
      this.hide(event)
    }
  }

  get localCheckboxes() {
    return this.element.querySelectorAll(this.checkboxSelectorValue)
  }

  get allCheckboxes() { return document.querySelectorAll(this.checkboxSelectorValue) }
}
